/**
 * @name 封装身份验证
 * @author 胡倍源
 * @mark 2024-09-12 非授权不得随意更改，违者承担法律责任
 */


/**
 * @name 用户身份验证(通过Token缓存和有效性验证)
 * @returns 验证通过返回true，否则返回false
 */
export function authentication() {
    //获取缓存并验证
    let token = JSON.parse(localStorage.getItem('token'));
    let uid = localStorage.getItem('uid');
    console.log(token);
    if (!token || !token.token || !token.tokenEffective || !token.tokenStart || !uid) {
        //无token
        return false;
    }
    const nowTime = Math.trunc(Date.now() / 1000);
    console.log(nowTime - parseInt(token.tokenStart))
    if (nowTime - parseInt(token.tokenStart) >= parseInt(token.tokenEffective)) {
        //token超时
        return false;
    }
    return true;
}
/**
 * 统一清除用户身份验证
 * @returns 清除身份验证
 */
export function clearAuthentication(){
    localStorage.removeItem('uid');
    localStorage.removeItem('token');
    return true;
}