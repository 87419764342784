/**
 * @name 封装常用的加解密方法
 * @author 胡倍源
 * @mark 2024-08-29 非授权不得随意更改，违者承担法律责任
 */


import * as CryptoJS from 'crypto-js';
import { des, tripleDES } from '../config/keyparameter';


//DES 加密(默认使用配置项的密钥)
export function encryptDes(data, key = des.key) {
  const ciphertext = CryptoJS.DES.encrypt(data, key, {
    mode: CryptoJS.mode.ECB, // 使用 ECB 模式--无向量
    padding: CryptoJS.pad.Pkcs7, // 使用 PKCS#7 填充方式
  });
  return ciphertext.toString();
}

//DES 解密(默认使用配置项的密钥)
export function decryptDes(data, key = des.key) {
  // 创建一个 DES 密码器
  const bytes = CryptoJS.DES.decrypt(data, key, {
    mode: CryptoJS.mode.ECB, // 使用 ECB 模式--无向量
    padding: CryptoJS.pad.Pkcs7, // 使用 PKCS#7 填充方式
  });
  const plaintext = bytes.toString(CryptoJS.enc.Utf8);
  return plaintext;
}

//3DES加密(默认使用配置项的密钥)
export function encryptWith3DES(data, key = tripleDES.key) {
  // 使用CryptoJS的TripleDES算法进行加密
  const encrypted = CryptoJS.TripleDES.encrypt(data, key, {
    mode: CryptoJS.mode.ECB, // 使用 ECB 模式--无向量
    padding: CryptoJS.pad.Pkcs7, // 使用 PKCS#7 填充方式
  });
  return encrypted.toString();
}

// 3DES解密函数
export function decryptWith3DES(data, key = tripleDES.key) {
  // 使用CryptoJS的TripleDES算法进行解密
  const bytes = CryptoJS.TripleDES.decrypt(data, key, {
    mode: CryptoJS.mode.ECB, // 使用 ECB 模式--无向量
    padding: CryptoJS.pad.Pkcs7 // 使用 PKCS#7 填充方式
  });
  return bytes.toString(CryptoJS.enc.Utf8);
};