import { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'antd';
import { Toast, Dialog, NumberKeyboard, PasscodeInput } from 'antd-mobile';
import { useNavigate, useLocation } from 'react-router-dom';
//页面组件、自定义方法、样式
import { encryptWith3DES } from '../../utils/cryptogram';
import { axiosWithToken } from '../../utils/request';
import { authentication } from '../../utils/authentication';
import './index.css';

const PasswordConfirmation = () => {
    const navigate = useNavigate(); //路由跳转
    const isInitialRender = useRef(true); // 用于标记是否首次渲染
    const [passwordRegx, setpasswordRegx] = useState(/^[0-9]{6}$/);
    const [inputError, setinputError] = useState(false);//用于标记密码输入框是否有错误
    //渲染页面--同时验证token有效性
    useEffect(() => {
        //非首次渲染则返回
        if (!isInitialRender.current) {
            return;
        }
        //用户身份验证
        const auth = authentication();
        if (!auth) {
            navigate('/', { replace: true });
            return;
        }
        isInitialRender.current = false;
    }, [])

    const getPassWord = (e) => {
        console.log(e)
        setinputError(false);
    }
    //校验密码
    const checkPassword = async (value) => {
        if (!value) {
            setinputError(true);
            return;
        }
        if (value.length != 6) {
            setinputError(true);
            return;
        }
        if (!value.match(passwordRegx)) {
            setinputError(true);
            return;
        }
        //验证成功
        try {
            const uid = localStorage.getItem('uid');
            const password = await encryptWith3DES(value);
            Toast.show({ icon: 'loading', content: '加载中', duration: 0, maskClickable: false })
            //请求登录接口，验证密码有效性
            const paramters = {
                account: uid,
                password: password
            }
            const respones = await axiosWithToken.post('/posts/login', paramters, { headers: { 'Content-Type': 'application/json' } });
            await Toast.clear();
            console.log(respones.data);
            if (respones.data.code == 602) {
                Toast.show({ icon: 'fail', content: '用户账户异常', duration: 2000, maskClickable: false })
                return;
            } else if (respones.data.code == 601) {
                setinputError(true);
                return;
            } else if (respones.data.code == 0) {
                //校验通过
                navigate('/resetpassword', { replace: true, state: { type: 2 } });
            } else {
                setinputError(true);
                return;
            }
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                Toast.show({ icon: 'fail', content: '请求超时', duration: 2000, maskClickable: false })
            } else {
                await Toast.clear();//先将请求时的loading清除
                Dialog.alert({ content: '系统异常，请稍后重试！', closeOnMaskClick: true });
            }
        }
    }

    return (
        <div>
            <Row>
                <Col span={2}></Col>
                <Col span={20} className='page-box'>
                    <Row>
                        <Col span={24} className='subtitle'>修改密码</Col>
                    </Row>
                    <Row>
                        <Col span={24} className='title'>请输入原6位数字密码</Col>
                    </Row>
                    <Row>
                        <Col span={24} className='title-tips'>修改密码前需对原密码进行二次校验</Col>
                    </Row>
                    <Row>
                        <Col span={24} className='password-input-box'>
                            {/* <Input.Password type='number' className='password-input' placeholder="请输入6位纯数字密码" onBlur={checkPassword} onChange={checkPassword} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} /> */}
                            <PasscodeInput className="passcode-container" seperated keyboard={<NumberKeyboard />} length={6} error={inputError} onFill={checkPassword} onChange={getPassWord} />
                        </Col>
                    </Row>
                </Col>
                <Col span={2}></Col>
            </Row>
        </div >
    )
}

export default PasswordConfirmation