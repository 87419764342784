import { Col, Row } from 'antd';
import { Tabs, Swiper } from 'antd-mobile'
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//页面组件、自定义函数、样式
import TemporayCardPage from './temporarycard';
import MenuPage from './menu';
import { authentication } from '../../utils/authentication';
import './index.css';
//图片引入


//定义页面tab栏数据
const tabItems = [
    { key: 'temporayCard', title: '临时出入证' },
    { key: 'menu', title: '功能菜单' },
]
const Home = () => {
    const navigate = useNavigate(); //路由跳转
    const isInitialRender = useRef(true); // 用于标记是否首次渲染
    //tabs配合手势左右滑动
    const [activeTabsIndex, setactiveTabsIndex] = useState(0);
    const swiperRef = useRef(null);

    //用户合法性校验
    useEffect(() => {
        //非首次渲染则返回
        if (!isInitialRender.current) {
            return;
        }
        //用户身份验证
        const auth = authentication();
        if (!auth) {
            navigate('/', { replace: true });
            return;
        }
        isInitialRender.current = false;
    }, [])

    const handleChangeToMenu = () => {
        setactiveTabsIndex(1); // 设置为菜单页面的索引
        swiperRef.current?.swipeTo(1); // 如果需要也可以进行滑动
    };
    //用户点击tabs事件
    const handleTabChange = (key) => {
        const index = tabItems.findIndex(item => item.key === key)
        setactiveTabsIndex(index)
        swiperRef.current?.swipeTo(index);
    };

    return (
        <div>
            <Row className='page-top-tabs-box'>
                <Col span={24} className='top-tabs-top'>
                </Col>
                <Col span={24}>
                    <Tabs className='top-tabs' activeKey={tabItems[activeTabsIndex].key} onChange={handleTabChange}>
                        {tabItems.map(item => (
                            <Tabs.Tab title={item.title} key={item.key} />
                        ))}
                    </Tabs>
                </Col>
            </Row>

            {/* <Row className='pagebottombox'>
                <Col span={24} className='bottom-tap-top'>
                </Col>
                <Col span={24} >
                    <Tabs className='bottom-tap' onChange={handleTabChange}>
                        <Tabs.Tab title="临时出入证" key="temporayCard">
                            <TemporayCardPage></TemporayCardPage>
                        </Tabs.Tab>
                        <Tabs.Tab title="功能菜单" key="menu">
                            <MenuPage></MenuPage>
                        </Tabs.Tab>
                    </Tabs>
                </Col>
            </Row> */}

            <Swiper
                direction='horizontal'
                indicator={() => null}
                ref={swiperRef}
                defaultIndex={activeTabsIndex}
                onIndexChange={index => {
                    setactiveTabsIndex(index)
                }}>
                <Swiper.Item>
                    <TemporayCardPage></TemporayCardPage>
                </Swiper.Item>
                <Swiper.Item>
                    <MenuPage onChangeToMenu={handleChangeToMenu} activeTabsIndex={activeTabsIndex}></MenuPage>
                </Swiper.Item>
            </Swiper>

        </div>
    )
}

export default Home;