import { useState, useEffect, useRef } from 'react';
import { Col, Row, Input, Button, Form } from 'antd';
import { Toast, Dialog } from 'antd-mobile';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
//页面组件、自定义方法、样式
import { encryptWith3DES } from '../../utils/cryptogram';
import { axiosWithToken } from '../../utils/request';
import { authentication } from '../../utils/authentication';
import './index.css';

const ResetPassWord = () => {
    const navigate = useNavigate(); //路由跳转
    const location = useLocation();//接受路由参数
    const urlParam = location.state;
    const [pageType, setPageType] = useState(2);//用于判断页面的展示类型；1首次使用修改密码；2常规修改密码
    const isInitialRender = useRef(true); // 用于标记是否首次渲染
    const [form] = Form.useForm();//创建form实例,用于调用表单验证
    const [passwordRegx, setpasswordRegx] = useState(/^[0-9]{6}$/);
    //渲染页面--同时验证token有效性
    useEffect(() => {
        //非首次渲染则返回
        if (!isInitialRender.current) {
            return;
        }
        //用户身份验证
        const auth = authentication();
        if (!auth) {
            navigate('/', { replace: true });
            return;
        }
        //页面渲染类型赋值
        if (urlParam && urlParam.type) {
            setPageType(urlParam.type);
        }
        isInitialRender.current = false;
    }, [])
    const checkNewPassword = (e) => {
        const password = e.target.value;
        if (!password) {
            form.setFields([{ name: 'newpasswordF', errors: ['密码输入框不能为空'] }]);
            return;
        }
        if (password.length != 6) {
            form.setFields([{ name: 'newpasswordF', errors: ['密码长度有误'] }]);
            return;
        }
        if (!password.match(passwordRegx)) {
            form.setFields([{ name: 'newpasswordF', errors: ['密码输入数据有误'] }]);
            return;
        }
    }
    const checkNewPasswordAgain = (e) => {
        const password = e.target.value;
        if (!password) {
            form.setFields([{ name: 'newpasswordS', errors: ['密码输入框不能为空'] }]);
            return;
        }
        if (password.length != 6) {
            form.setFields([{ name: 'newpasswordS', errors: ['密码长度有误'] }]);
            return;
        }
        //密码正则表达验证
        if (!password.match(passwordRegx)) {
            form.setFields([{ name: 'newpasswordS', errors: ['密码输入数据有误'] }]);
            return;
        }
    }
    const onFinish = async (values) => {
        let passwordF = true;
        let passwordS = true;
        //密码正则表达验证
        if (!values.newpasswordF.match(passwordRegx)) {
            form.setFields([{ name: 'newpasswordF', errors: ['密码输入数据有误'] }]);
            passwordF = false;
        }
        if (!values.newpasswordS.match(passwordRegx)) {
            form.setFields([{ name: 'newpasswordS', errors: ['密码输入数据有误'] }]);
            passwordS = false;
        }
        if (values.newpasswordS !== values.newpasswordF) {
            form.setFields([{ name: 'newpasswordS', errors: ['两次密码输入不一致'] }]);
            passwordS = false;
        }
        if (!passwordF || !passwordS) {
            return;
        }
        //验证成功
        try {
            const uid = localStorage.getItem('uid');
            const newpassword = await encryptWith3DES(values.newpasswordF);
            Toast.show({ icon: 'loading', content: '加载中', duration: 0, maskClickable: false })
            const paramters = {
                uid: uid,
                password: newpassword
            }
            const respones = await axiosWithToken.post('/posts/resetPassword', paramters, { headers: { 'Content-Type': 'application/json' } });
            await Toast.clear();
            console.log(respones.data);
            if (respones.data.code == 602) {
                Toast.show({ icon: 'fail', content: '账户异常', duration: 2000, maskClickable: false })
                return;
            } else if (respones.data.code == 605) {
                form.setFields([{ name: 'newpasswordF', errors: ['新密码不能与原密码相同'] }]);
                return;
            } else if (respones.data.code == 0) {
                Toast.show({ icon: 'success', content: '设置成功', duration: 2000, maskClickable: false });
                setTimeout(() => {
                    //放在定时器里，让toast显示结束后，再页面跳转
                    navigate('/Home', { replace: true });
                }, 2000)
            } else {
                Toast.show({ icon: 'fail', content: '重置失败', duration: 2000, maskClickable: false })
                return;
            }
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                Toast.show({ icon: 'fail', content: '请求超时', duration: 2000, maskClickable: false })
            } else {
                await Toast.clear();//先将请求时的loading清除
                Dialog.alert({ content: '系统异常，请稍后重试！', closeOnMaskClick: true });
            }
        }
    }

    return (
        <div>
            <Row>
                <Col span={2}></Col>
                <Col span={20} className='page-box'>
                    <Form name="resetPasswordForm" form={form} onFinish={onFinish}>
                        <Row>
                            <Col span={24} className='subtitle'>{pageType == 1 ? '首次使用' : '修改密码'}</Col>
                        </Row>
                        <Row>
                            <Col span={24} className='title'>{pageType == 1 ? '需设置新的6位数字密码' : '可设置新的6位数字密码'}</Col>
                        </Row>
                        <Row>
                            <Col span={24} className='title-tips'>{pageType == 1 ? '首次使用时，建议将原始密码进行重置' : '修改完成后，需要退出系统并重新登录'}</Col>
                        </Row>
                        <Form.Item name="newpasswordF" rules={[{ required: true, message: '密码输入框不能为空' }]}>
                            <Row>
                                <Col span={24} className='newpassword-input-box'>
                                    <Input.Password type='number' className='newpassword-input' placeholder="请输入6位纯数字密码" onBlur={checkNewPassword} onChange={checkNewPassword} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item name="newpasswordS" rules={[{ required: true, message: '密码输入框不能为空' }]}>
                            <Row>
                                <Col span={24} className='newpassword-input-box'>
                                    <Input.Password type='number' className='newpassword-input' placeholder="请再次输入密码" onBlur={checkNewPasswordAgain} onChange={checkNewPasswordAgain} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                                </Col>
                            </Row>
                        </Form.Item>
                        <Row>
                            <Col span={24} className='reset-buttom-box'>
                                <Button type="primary" className='reset-buttom' htmlType="submit">{pageType == 1 ? '确认重置' : '确认修改'}</Button>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col span={24} className='skip-buttom-box'>
                                <Button className='skip-buttom' onClick={skip}>暂时跳过</Button>
                            </Col>
                        </Row> */}
                    </Form>
                </Col>
                <Col span={2}></Col>
            </Row>
        </div >
    )
}

export default ResetPassWord