/**
 * @name 封装接口请求
 * @author 胡倍源
 * @mark 2024-09-12 非授权不得随意更改，违者承担法律责任
 */


import axios from 'axios';
import * as myConfig from '../config/config'

//创建带Token的axios请求实例
const axiosWithToken = axios.create({
    baseURL: myConfig.config.apiRoot, // 替换为你的后端 API 地址
    timeout: 20 * 1000, // 设置超时时间为毫秒
});
//创建不带Token的axios请求实例
const axiosWithoutToken = axios.create({
    baseURL: myConfig.config.apiRoot, // 替换为你的后端 API 地址
    timeout: 20 * 1000, // 设置超时时间为 5000 毫秒（5 秒）
});

// 请求拦截器
axiosWithToken.interceptors.request.use(
    (config) => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            config.headers.Authorization = `Bearer ${token.token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
axiosWithToken.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // 处理响应错误
        return Promise.reject(error);
    }
);

export { axiosWithToken, axiosWithoutToken };