import { Col, Row } from 'antd';
import './index.css';

const userule = () => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24} className='title-box'>《关于遵守出入证使用管理规定的承诺书》</Col>
                    </Row>
                    <Row>
                        <Col span={24} className='author-box'>票务管理室&nbsp;编制</Col>
                    </Row>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22} className='ruleinfo-title-box'></Col>
                        <Col span={1}></Col>
                    </Row>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22}>
                            <div className='ruleinfo-content'>
                                为了更好地执行和遵守《佛山地铁工作证件管理办法》中的有关规定，现将
                                有关注意事项通知如下：
                            </div>
                            <div className='ruleinfo-content'>
                                <span className='strong-content'>第一条</span>出入证仅限持证人本人使用，且是搭乘地铁的唯一有效证件。
                            </div>
                            <div className='ruleinfo-content'>
                                <span className='strong-content'>第二条</span>申领人申请出入证，需先接受出入证使用培训，并认真学习本承诺
                                书内容。
                            </div>
                            <div className='ruleinfo-content'>
                                <span className='strong-content'>第三条</span>持证人遗失出入证应及时向本单位业务负责人报备，单位业务负责
                                人收到信息后立即向运营公司对口业务管理单位业务对接人报备，并及时办理补
                                办手续，缴纳补办款100元。如未及时报失，且被他人使用的，按借予他人使用
                                予以处理。
                            </div>
                            <div className='ruleinfo-content'>
                                <span className='strong-content'>第四条</span>持证人应爱护并妥善保管出入证，防止出入证遗失，持证人1个自
                                然年度内遗失3次及以上的，当年度不予补办出入证。持证人在出入证补办、停
                                用期间进出付费区的须自行购票。
                            </div>
                            <div className='ruleinfo-content'>
                                <span className='strong-content'>第五条</span>擅自涂画、篡改、折损出入证和破坏出入证信息，持证人须缴纳补
                                办证件的工本费100元。
                            </div>
                            <div className='ruleinfo-content'>
                                <span className='strong-content'>第六条</span>持证人应积极配合、自觉接受车站票务稽查人员的检查。运营公司
                                安全监察部定期根据中央计算机有关车票使用记录开展稽查。若出现违规情况，
                                须向运营公司缴纳违约金和违规车款。
                            </div>
                            <div className='ruleinfo-content'>
                                <span className='strong-content'>第七条</span>持证人不再符合发放条件时，须办理出入证退还手续，如出入证已
                                丢失的，须缴纳证件工本费100元后方可办理调动、离职等手续。
                            </div>
                            <div className='ruleinfo-content'>
                                <span className='strong-content'>以下内容为出入证使用要求及违规处理，请申请人仔细阅读：</span>
                            </div>
                            <div className='ruleinfo-content'>
                                <span className='strong-content'>第八条</span>持证人禁止下列行为：
                            </div>
                            <div className='ruleinfo-content'>
                                （一）转借、转赠、转让出入证供他人使用或其他原因导致证件被他人使用。
                            </div>
                            <div className='ruleinfo-content'>
                                （二）利用出入证带人、协助他人进出地铁付费区。
                            </div>
                            <div className='ruleinfo-content'>
                                （三）非特殊情况利用出入证二进二出（含多进多出）。
                            </div>
                            <div className='ruleinfo-content'>
                                （四）出入证使用违反本办法及相关合同、协议。
                            </div>
                            <div className='ruleinfo-content'>
                                （五）伪造、变造、复制出入证或申请资料等不符合出入证使用要求的其他
                                行为。
                            </div>
                            <div className='ruleinfo-content'>
                                <span className='strong-content'>第九条</span>违规情况的处理：
                            </div>
                            <div className='ruleinfo-content'>
                                （一）持证人有上述第八条（一）至（四）规定情形的，作如下处理：
                            </div>
                            <div className='ruleinfo-content'>
                                1.从核实确定违规之日起停止其出入证的乘车功能，12个月后恢复出入证
                                的乘车功能。
                            </div>
                            <div className='ruleinfo-content'>
                                2.出现该情形被停止出入证乘车功能两次者，永久取消其证件的乘车功能。
                            </div>
                            <div className='ruleinfo-content'>
                                3.须向运营公司缴纳违约金1000元及违规车款。
                            </div>
                            <div className='ruleinfo-content'>
                                （二）持证人有上述第八条（五）规定情形的，作如下处理：
                            </div>
                            <div className='ruleinfo-content'>
                                1.发现当日回收出入证并永久取消其证件的乘车功能。
                            </div>
                            <div className='ruleinfo-content'>
                                2.情节恶劣或后果严重的，给予解除劳动合同或移送公安机关处理。
                            </div>
                            <div className='ruleinfo-content'>
                                3.须向运营公司缴纳违约金1000元及违规车款。
                            </div>
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22} className='ruleinfo-title-box'></Col>
                        <Col span={1}></Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default userule;