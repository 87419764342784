import './App.css';
// 导入 react-helmet
import { Helmet } from 'react-helmet';
//路由配置
import { BrowserRouter as Router, Route, Routes, Navigate, } from 'react-router-dom';
//引入展示版面
import Home from "./pages/home";
import Login from './pages/login';
import Scan from "./pages/scan";
import ResetPassWord from './pages/resetpassword';
import PasswordConfirmation from './pages/passwordconfirmation';
import UseRule from './pages/userule';

function App() {

  return (
    <Router>
      <Helmet>
        <title>佛山地铁企业服务</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/scan" element={<Scan />} />
        <Route path="/passwordconfirmation" element={<PasswordConfirmation />} />
        <Route path="/resetpassword" element={<ResetPassWord />} />
        <Route path="/userule" element={<UseRule />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>


    // <Login></Login>
    // <Idcard></Idcard>
    // <ChangePassWord></ChangePassWord>
  )

}

export default App;
