import { useState, useEffect, useRef } from 'react';
import { Col, Row, Input, Button, Form } from 'antd';
import { Toast, Dialog, Checkbox } from 'antd-mobile';
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
//页面组件、自定义方法、样式
import { encryptWith3DES } from '../../utils/cryptogram';
import { axiosWithoutToken } from '../../utils/request';
import { authentication } from '../../utils/authentication';
import './index.css';



const Login = () => {
    const navigate = useNavigate(); //路由跳转
    const [form] = Form.useForm();//创建form实例,用于调用表单验证
    const [rememberMe, setremeberMe] = useState(false);
    const rememberMeRef = useRef(rememberMe);
    const isInitialRender = useRef(true); // 用于标记是否首次渲染
    const [showLoginPage, setShowLoginPage] = useState(false);//用于控制登录页面的显示与隐藏
    //用户合法性校验
    useEffect(() => {
        //非首次渲染则返回
        if (!isInitialRender.current) {
            return;
        }
        //用户身份验证
        const auth = authentication();
        if (auth) {
            //通过验证则自动前往主页
            navigate('/home', { replace: true });
            return;
        }
        setShowLoginPage(true);
        isInitialRender.current = false;
    }, [])
    //忘记密码按钮
    const changePassWord = async () => {
        const result = await Dialog.alert({
            title: '重要提示', content: (
                <>
                    <p>1.本功能仅面向具有企业服务权限的用户使用，非权限用户请勿操作。</p>
                    <p>2.如您忘记密码，请致电佛山地铁24小时官方客服热线：0757-88888888 申请密码重置。</p>
                </>
            ), closeOnMaskClick: true
        })
    }
    //14日免登录复选框
    const changeRemeberMe = (event) => {
        setremeberMe(event);
    }
    useEffect(() => {
        rememberMeRef.current = rememberMe;
    }, [rememberMe])
    //点击登录
    const onFinish = async (values) => {

        if (!values.account || !values.password) {
            form.setFields([
                { name: 'account', errors: ['账户信息不能为空！'] },
                { name: 'password', errors: ['用户密码不能为空！'] }
            ]);
            return;
        }
        //账户密码正则表达验证
        const account_regx = /^(13|14|15|17|18)\d{9}$|^[0-9]{6}$/;
        const password_regx = /^[0-9]{6}$/;
        let accinde = true;
        let passinde = true;
        if (!values.account.match(account_regx)) {
            form.setFields([{ name: 'account', errors: ['账户格式不正确！'] }]);
            accinde = false
        }
        if (!values.password.match(password_regx)) {
            form.setFields([{ name: 'password', errors: ['密码格式不正确！'] }]);
            passinde = false;
        }
        if (!accinde || !passinde) {
            return;
        }
        //用户数据初步校验通过,对密码进行3des加密，发送接口请求--是否加loading
        try {
            values.password = await encryptWith3DES(values.password);
            Toast.show({ icon: 'loading', content: '加载中', duration: 0, maskClickable: false })
            const paramters = {
                account: values.account,
                password: values.password
            }
            const respones = await axiosWithoutToken.post('/posts/login', paramters, { headers: { 'Content-Type': 'application/json' } });
            await Toast.clear();
            console.log(respones.data);
            if (respones.data.code == 602) {
                form.setFields([{ name: 'account', errors: ['查询无此账户！'] }]);
                return;
            } else if (respones.data.code == 601) {
                form.setFields([{ name: 'password', errors: ['用户密码错误！'] }]);
                return;
            } else if (respones.data.code === 0) {
                //登录成功--设置Token和用户uid缓存
                const tokenStorageData = {
                    token: respones.data.data.userToken,
                    tokenStart: respones.data.data.tokenStart,
                    tokenEffective: rememberMeRef.current ? respones.data.data.tokenEffective : 60 * 10,//判断是否设置14天免登录，是则用接口数据，否则10分钟后失效
                }
                localStorage.setItem('token', JSON.stringify(tokenStorageData));
                localStorage.setItem('uid', respones.data.data.uid);
                console.log(localStorage.getItem('token'));
                if (respones.data.data.firsttime == 1) {
                    //首次使用跳转重置密码
                    navigate('/resetpassword', { state: { type: 1 } });
                } else {
                    //跳转至临时出入证页面
                    navigate('/Home');
                }
            } else {
                form.setFields([{ name: 'account', errors: ['输入账户有误！'] }]);
                return;
            }
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                Toast.show({ icon: 'fail', content: '请求超时', duration: 2000, maskClickable: false })
            } else {
                await Toast.clear();//先将请求时的loading清除
                Dialog.alert({ content: '系统异常，请稍后重试！', closeOnMaskClick: true });
            }
        }
    };

    return (
        <>
            {showLoginPage && (
                <Row>
                    <Col span={2}></Col>
                    <Col span={20} className='page-box'>
                        <Form name="loginForm" form={form} onFinish={onFinish}>
                            <Row>
                                <Col span={24} className='subtitle'><label className='subtitle-content'>欢迎使用</label></Col>
                            </Row>
                            <Row>
                                <Col span={24} className='title'><label className='title-content'>佛山地铁企业服务</label></Col>
                            </Row>
                            <Row>
                                <Col span={24} className='title-tips'><label className='title-tips-content'>您好，请在下方输入账号/手机号和密码进行登录</label></Col>
                            </Row>
                            <Form.Item name="account" rules={[{ required: true, message: '账户信息不能为空！' }]}>
                                <Row>
                                    <Col span={24} className='account-input-box'>
                                        <Input className='account-input' placeholder="请输入企业服务账号/手机号" prefix={<UserOutlined />} />
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item name="password" rules={[{ required: true, message: '用户密码不能为空！' }]}>
                                <Row>
                                    <Col span={24} className='password-input-box'>
                                        <Input.Password className='password-input' placeholder="请输入6位数字密码" prefix={<LockOutlined />} iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Row>
                                <Col span={24} className='change-password'><label onClick={changePassWord}>忘记密码 ?</label></Col>
                            </Row>
                            <Row>
                                <Col span={24} className='rememberme-box'>
                                    14日内免登录&nbsp;&nbsp;<Checkbox className='rememberme-input' checked={rememberMe} onChange={changeRemeberMe}></Checkbox>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='login-buttom-box'>
                                    <Button type="primary" className='login-buttom' htmlType="submit">点击登录</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} className='tips-content'>* 本功能仅面向具有企业服务权限的内部用户使用</Col>
                            </Row>
                            <Row>
                                <Col span={24} className='bottom-box'></Col>
                            </Row>
                            {/* <Row>
                            <Col span={24} className='bottom-content'>本服务由佛山市地铁运营有限公司提供</Col>
                        </Row> */}
                        </Form>
                    </Col>
                    <Col span={2}></Col>
                </Row>
            )}
        </>
    )
}

export default Login