import { Col, Row } from 'antd';
import { Toast, Dialog, ErrorBlock, Skeleton, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep'
import { useState, useEffect, useRef } from 'react';
import { QRCodeSVG } from 'qrcode.react'; //引入二维码组件

//页面组件、自定义函数、样式
import { axiosWithToken } from '../../utils/request';
import { encryptDes } from '../../utils/cryptogram';
import * as myfunction from '../../utils/myfunction'
import './temporarycard.css';

//图片引入
import personalPath from '../../images/personalpic.png'

const TemporayCardPage = () => {
    const isInitialRender = useRef(true); // 用于标记是否首次渲染
    const [userInfo, setUserInfo] = useState({});//存储用户信息
    const [qrcode, setqrcode] = useState('');//存储二维码数据
    const [qrcodePlaintext, setqrcodePlaintext] = useState('');//存储临时二维码数据（接口明文返回数据）
    const [localClock, setlocalClock] = useState(null);//存储本地时钟
    const [showErrorBlock, setshowErrorBlock] = useState(false);//是否展示错误页
    const [isLoading, setIsLoading] = useState(true);//是否显示加载中骨架屏

    //设置本地时钟
    useEffect(() => {
        let timerId = setInterval(() => {
            let clockTime = myfunction.formatCurrentTime();
            setlocalClock(clockTime);
        }, 1000)
        //页面卸载时清除定时器
        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        }
    }, [localClock])

    //设置动态二维码
    useEffect(() => {
        let qrcodeId = setInterval(() => {
            let tempQRcode = encryptDes(qrcodePlaintext);
            setqrcode(tempQRcode)
        }, 3 * 1000);//3秒刷新二维码
        //页面卸载时清除定时器
        return () => {
            if (qrcodeId) {
                clearInterval(qrcodeId);
            }
        }
    }, [qrcode]);

    useEffect(() => {
        //非首次渲染则返回
        if (!isInitialRender.current) {
            return;
        }
        request();
        isInitialRender.current = false;
    }, [])

    //下拉刷新功能
    const doRefresh = async () => {
        //下拉刷新时需展示骨架屏，不然不知道是否在刷新
        if (!isLoading) {
            setIsLoading(true);
            await request();
            // await sleep(10000);

        }
    }

    //发起接口请求
    const request = async () => {
        //开始请求
        try {
            const uid = localStorage.getItem('uid');
            const paramters = {
                uid: uid
            };
            const respones = await axiosWithToken.post('/posts/getUserInfo', paramters, { headers: { 'Content-Type': 'application/json' } });
            console.log(respones.data);
            if (!respones.data || !respones.data.data) {
                //接口返回异常
            } else if (respones.data.code !== 0) {
                //接口状态有误
            } else if (respones.data.code == 0) {
                //接口请求成功--处理数据
                const userData = {
                    userName: respones.data.data.userName,
                    companyShort: respones.data.data.companyShort,
                    scope: respones.data.data.scope,
                    deadLine: respones.data.data.deadLine.substring(0, 4) + "年" + respones.data.data.deadLine.substring(5, 7) + "月" + respones.data.data.deadLine.substring(8, 10) + "日",
                    uid: respones.data.data.uid,
                    profilePic: respones.data.data.profilePic,
                    status: respones.data.data.status,
                    temporarycard_type: respones.data.data.temporarycard_type,
                    qrcodeData: 'https://www.baidu.com',
                }
                setUserInfo(userData);
                setqrcodePlaintext(userData.qrcodeData);
            }
        } catch (error) {
            //系统异常
            if (error.code === 'ECONNABORTED') {
                Toast.show({ icon: 'fail', content: '请求超时', duration: 2000, maskClickable: false })
            } else {
                await Toast.clear();//先将请求时的loading清除
                Dialog.alert({ content: '系统异常，请稍后重试！', closeOnMaskClick: true });
            }
            setshowErrorBlock(true);
        } finally {
            //无论成功失败都要终止加载骨架屏
            setIsLoading(false);
        }
    }

    //根据用户的status动态设置颜色
    const getStatusStyle = (status) => {
        switch (status) {
            case 0:
                return { color: '#f89b04', fontWeight: '600' };
            case 1:
                return { color: '#1cba50', fontWeight: '600' };
            case 2:
                return { color: '#ff0000', fontWeight: '600' };
            default:
                return { color: '#fff', fontWeight: '600' };
        }
    };
    //根据用户的temporarycard_type动态显示卡片背景
    const getTemporarycardStyle = (temporarycard_type) => {
        switch (temporarycard_type) {
            case 1: return 'cardtitle-yunying';
            default: return 'cardtitle-choubei';
        }
    }

    //定义骨架屏样式和占位符
    const preparePage = () => {
        if (isLoading) {
            //骨架屏页面
            return (
                <>
                    <Row>
                        <Col span={24} style={{ height: '5vh' }}>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={3}></Col>
                        <Col span={18}>
                            {/* <Skeleton.Title animated /> */}
                            <Skeleton.Paragraph lineCount={8} animated />
                        </Col>
                        <Col span={3}></Col>
                    </Row>
                </>
            );
        } else {
            //正常页面
            return (
                <>
                    <Row>
                        <Col span={24} className='personal-name'>
                            {userInfo.userName}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className='card-no'>NO：LS {userInfo.uid}</Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={10} className='personal-info-title'>单位名称：</Col>
                                <Col span={14} className='personal-info-content'>{userInfo.companyShort}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={10} className='personal-info-title'>适用范围：</Col>
                                <Col span={14} className='personal-info-content'>{userInfo.scope}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={10} className='personal-info-title'>证件状态：</Col>
                                <Col span={14} className='personal-info-content' style={getStatusStyle(userInfo.status)}>{userInfo.status == 0 ? "待审核" : userInfo.status == 1 ? "正常" : userInfo.status == 2 ? "已失效" : ''}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={10} className='personal-info-title'>有效期至：</Col>
                                <Col span={14} className='personal-info-content'>{userInfo.deadLine}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='card-qrcode-box'>
                        <Col span={7}></Col>
                        <Col span={10} className='card-qrcode'>
                            {/* <img className='card-qrcode-img' src={qrcodePath} alt="二维码" /> */}
                            <QRCodeSVG className='card-qrcode-img' value={qrcode} />
                        </Col>
                        <Col span={7}></Col>
                    </Row>
                </>
            );
        }
    }

    return (
        <>
            {showErrorBlock && (
                <Row justify="center">
                    <Col span={24} className='error-block'>
                        <ErrorBlock status="default" fullPage={true} />
                    </Col>
                </Row>
            )}
            {!showErrorBlock && (
                <>
                    {/* <PullToRefresh onRefresh={doRefresh} refreshingText="玩命加载中" canReleaseText="松开即可刷新" completeText="刷新成功了"> */}
                        <Row>
                            <Col span={24} className='temporarycard-page-box'>
                                <Row justify="center">
                                    <Col span={24} className="pagetopbox">
                                        <Row>
                                            <Col span={3}></Col>
                                            <Col className="cardbox" span={18}>
                                                <Row>
                                                    <Col span={24} className={getTemporarycardStyle(userInfo.temporarycard_type)}>
                                                        佛山地铁{userInfo.temporarycard_type == 1 ? '运营期' : '筹备期'}临时出入证
                                                        <img className='personal-img' src={personalPath} alt="头像" />
                                                    </Col>
                                                </Row>
                                                {preparePage()}
                                            </Col>
                                            <Col span={3}></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row justify="center">
                                    <Col span={24} className="pagemiddlebox">
                                        <Row className='middle-clock-box'>
                                            <Col span={2}></Col>
                                            <Col span={20} >
                                                <Row>
                                                    <Col span={24} className='middle-clock'>{localClock}</Col>
                                                </Row>
                                            </Col>
                                            <Col span={2} >
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={5}></Col>
                                            <Col span={19} className='swipeleft'></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    {/* </PullToRefresh> */}
                </>
            )}
        </>
    )
}

export default TemporayCardPage;