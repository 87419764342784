import Scanner from './scanner';

const scan = () => {
    return (
      <div>
        <h1>欢迎使用扫码功能!</h1>
        <Scanner />
      </div>
    );
  };
  
  export default scan;