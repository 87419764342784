/**
 * @name 封装常用的方法
 * @author 胡倍源
 * @mark 2024-08-29 非授权不得随意更改，违者承担法律责任
 */
// 原文转base64
export function transformBase64(data) {
    const buffer = Buffer.from(data);
    return buffer.toString('base64');
}
//base64转成原文
export function base64ToOriginal(data) {
    const buffer = Buffer.from(data, 'base64');
    return buffer.toString('ascii');
}
//
/**
 * 获取当前时间 格式为2024-08-29 16：16：54
 * @param timestemp 时间戳(秒级)，如果有时间戳，则将时间戳进行格式化
 * @returns 格式化后的时间
 */
export function formatCurrentTime(timestemp = '') {
    let currentDate = timestemp == '' ? new Date() : new Date(parseInt(timestemp + '000'));
    let year = currentDate.getFullYear();
    let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份需要加1，且补零
    let day = String(currentDate.getDate()).padStart(2, '0'); // 补零
    let hours = String(currentDate.getHours()).padStart(2, '0'); // 补零
    let minutes = String(currentDate.getMinutes()).padStart(2, '0'); // 补零
    let seconds = String(currentDate.getSeconds()).padStart(2, '0'); // 补零
    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
}