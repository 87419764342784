import { Col, Row } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { Toast, Dialog, Swiper } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

//页面组件、自定义函数、样式
import { clearAuthentication } from '../../utils/authentication';
import './menu.css';

//图片引入
import banner1 from '../../images/banner1.png';
import banner2 from '../../images/banner2.png';
import banner3 from '../../images/banner3.jpg';
import banner4 from '../../images/banner4.jpg';

import scanpath from '../../images/scan.png'
import approvepath from '../../images/approve.png'
import passwordpath from '../../images/password.png'
import userulepath from '../../images/userule.png'
import logoutpath from '../../images/logout.png'


const MenuPage = ({onChangeToMenu,activeTabsIndex}) => {
    const navigate = useNavigate(); //路由跳转


    //页面响应事件
    const comingsoon = () => {
        Toast.show({ content: '即将开放', duration: 2000, maskClickable: false })
    }

    //扫一扫
    const startScan = () => {
        // navigate('/scan');
    }

    //修改密码
    const changePassword = () => {
        // navigate(`/passwordconfirmation?activeTab=${activeTabsIndex}`);
        navigate(`/passwordconfirmation`);
    };

    //使用规则
    const useRule = () => {
        navigate('/userule');
    }

    //退出登录
    const logout = () => {
        Dialog.confirm({
            content: '您确定要退出登录吗？',
            onConfirm: () => {
                clearAuthentication();
                navigate('/', { replace: true });
                return;
            }
        })
    }


    return (
        <>
            <Row>
                <Col span={24} className='menu-page-box'>
                    <Row>
                        <Col span={24} className='menu-page-top-box'>
                            <Row >
                                <Col span={2}>
                                </Col>
                                <Col span={22} className='menu-page-top-image'>

                                    {/* <Swiper direction='horizontal' loop autoplay={true}>
                                            <Swiper.Item >
                                                <img className='banner-image' src={banner1} />
                                            </Swiper.Item>
                                            <Swiper.Item className='banner-image'>
                                                <img className='banner-image' src={banner2} />
                                            </Swiper.Item>
                                            <Swiper.Item className='banner-image'>
                                                <img className='banner-image' src={banner3} />
                                            </Swiper.Item>
                                            <Swiper.Item className='banner-image'>
                                                <img className='banner-image' src={banner4} />
                                            </Swiper.Item>
                                        </Swiper> */}

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className='menu-page-middle-box'>
                            <Row>
                                <Col span={24} className='menu-page-middle-title'></Col>
                            </Row>
                            {/* <Row className='menu-page-middle-menulist' onClick={comingsoon}>
                                <Col span={4} className='menulist-icon-box'>
                                    <img className='menulist-icon' src={scanpath} alt=""></img>
                                </Col>
                                <Col span={20} className='menulist-content'>扫一扫</Col>
                            </Row>
                            <Row className='menu-page-middle-menulist' onClick={comingsoon}>
                                <Col span={4} className='menulist-icon-box'>
                                    <img className='menulist-icon' src={approvepath} alt=""></img>
                                </Col>
                                <Col span={20} className='menulist-content'>流程申请</Col>
                            </Row> */}
                            <Row className='menu-page-middle-menulist' onClick={changePassword}>
                                <Col span={4} className='menulist-icon-box'>
                                    <img className='menulist-icon' src={passwordpath} alt=""></img>
                                </Col>
                                <Col span={20} className='menulist-content'>修改密码</Col>
                            </Row>
                            <Row className='menu-page-middle-menulist' onClick={useRule}>
                                <Col span={4} className='menulist-icon-box'>
                                    <img className='menulist-icon' src={userulepath} alt=""></img>
                                </Col>
                                <Col span={20} className='menulist-content'>使用规则</Col>
                            </Row>
                            <Row className='menu-page-middle-menulist' onClick={logout}>
                                <Col span={4} className='menulist-icon-box'>
                                    <img className='menulist-icon' src={logoutpath} alt=""></img>
                                </Col>
                                <Col span={20} className='menulist-content'>退出</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default MenuPage;